'use client';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
  EffectFade,
} from 'swiper/modules';
import { ReactNode } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { default_width } from '@/lib/constatns';
import swiperStyles from '@/style/swiperStyles';

interface Props {
  slider: Array<ReactNode>;
}

function HeaderCarouselWrapper({ slider }: Props) {
  return (
    <div className={`m-auto ${default_width} max-h-[35rem]`}>
      <Swiper
        style={swiperStyles}
        navigation={true}
        modules={[
          Navigation,
          Pagination,
          Scrollbar,
          A11y,
          Autoplay,
          EffectFade,
        ]}
        slidesPerView={1}
        loop={true}
        pagination={{ clickable: true, dynamicBullets: true }}
        scrollbar={{ draggable: true }}
        autoplay={{ delay: 2500 }}
      >
        {slider?.map((slide) => (
          <SwiperSlide key={uuidv4()}>{slide}</SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default HeaderCarouselWrapper;
