'use client';
import React from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, FreeMode } from 'swiper/modules';
import Link from 'next/link';
import Image from 'next/image';

export default function BrandWidget() {
  const Mark = [
    { link: 'https://www.egger.com/pl/?country=PL', img: 'EGGER.webp' },
    { link: 'https://kronosfera.pl/', img: 'KRONOSPAN.webp' },
    { link: 'https://maag-polska.pl/', img: 'MAAG.webp' },
    { link: 'https://cleaf.egger.page/pl/', img: 'CLEAF.webp' },
    { link: 'https://www.rehau.com/pl-pl', img: 'REHAU.webp' },
    { link: 'https://www.gtv.com.pl/pl/', img: 'GTV.webp' },
    { link: 'https://zbytex.com.pl/', img: 'ZBYTEX.webp' },
    { link: 'https://zadrozni.pl/', img: 'ZADROZNI.webp' },
  ];

  return (
    <div className="bg-white ">
      <div className="mx-auto max-w-7xl py-4 px-4 sm:py-8 sm:px-6 lg:px-8  mb-6">
        <div className="sm:flex sm:items-baseline sm:justify-between">
          <h2 className="text-2xl font-mono tracking-tight text-black hover:text-indigo-500 mt-5 mb-5 ">
            {' '}
            Nasi Partnerzy
          </h2>
        </div>
        <Swiper
          slidesPerView={5}
          autoplay={{ delay: 2000 }}
          spaceBetween={20}
          freeMode={true}
          pagination={{
            clickable: true,
          }}
          modules={[FreeMode, Autoplay]}
          className="mySwiper"
        >
          {Mark.map((e, i) => {
            return (
              <SwiperSlide key={i}>
                <Link href={e.link}>
                  <div className="bg-white border md:h-48 h-20 w-auto flex items-center justify-center">
                    <Image
                      width={300}
                      height={300}
                      alt={`${e.link}`}
                      className="object-contain h-32 w-32 "
                      src={`/MARKI/${e.img}`}
                    />
                  </div>
                </Link>
              </SwiperSlide>
            );
          })}
        </Swiper>

        <div className="mt-6 text-xl font-mono  justify-end flex flex-row w-full  ">
          <a href="./marki">Zobacz wszystkie</a>
        </div>
      </div>
    </div>
  );
}
