interface CustomCSSProperties extends React.CSSProperties {
  '--swiper-pagination-color'?: string;
  '--swiper-pagination-bullet-inactive-color'?: string;
  '--swiper-pagination-bullet-border'?: string;
  '--swiper-pagination-bullet-size'?: string;
  '--swiper-pagination-bullet-horizontal-gap'?: string;
  '--swiper-pagination-bullet-inactive-opacity'?: string;
}

const swiperStyles: CustomCSSProperties = {
  '--swiper-pagination-color': '#FFFFFF', // Kolor aktywnego kropka
  '--swiper-pagination-bullet-inactive-color': 'gray', // Kolor tła nieaktywnego kropka
  '--swiper-pagination-bullet-border': '2px solid #FFFFFF', // Ramka dla wszystkich kropek
  '--swiper-pagination-bullet-size': '20px', // Rozmiar kropka

  '--swiper-pagination-bullet-horizontal-gap': '10px', // Odstęp między kropkami
  '--swiper-pagination-bullet-inactive-opacity': '1', // Przezroczystość nieaktywnego kropka (ustawiamy na 1, ponieważ kolor tła jest już ustawiony na transparent)
};

export default swiperStyles;
